import { Outlet, Link } from "react-router-dom";
import '../App.css';
import { Navigation, PageFooter } from '../Components';
import { HeaderBar } from '../Atoms';

const Layout = () => {
  return (
    <div className="App">
        <HeaderBar/>
        <Outlet />
        <PageFooter/>
    </div>
  )
};

export default Layout;