import React from 'react';

export default function PageFooter() {
	return (
		<footer className="App-Footer">
			MSheets LLC <br />
			<br />
			Highlands Ranch CO, 80130 <br />
			<br />
		</footer>
	);
}
