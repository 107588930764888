import React from "react";
import { start } from "repl";
import { Navigation } from '../Components';

export default function HeaderBar () {
    const style = {
        pageHeader: {
            display: 'flex',
            FlexDirection: 'row',
            alignContent: 'start'
        },
        logo:{
            justifyContent: 'flex-start',
            height: '75px'
        }
    }
    return(
    <header className="App-header">
        <div className='pageHeader' style={style.pageHeader}>
        <img className="headerLogoImage"src={"../logo.png"} alt="logo" style={style.logo}/>
        </div>
        <div className='Title'>MSheets</div>
        <Navigation/>
    </header>
    )
} 