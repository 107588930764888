import React, { useEffect } from 'react';
import { ReactDOM } from 'react';
import './eclubForm.css';
import { useForm } from 'react-hook-form';
import config from '../../Constants/config';

export default function EclubForm() {
	// const [fNameText, fNameSet] = React.useState('');
	// const [lNameText, lNameSet] = React.useState('');
	// const [emailText, emailSet] = React.useState('');
	// const [companyText, companySet] = React.useState('');
	// const [commentText, commentSet] = React.useState('');
	const [successMessage, setSuccessMessage] = React.useState('');
	const [errorMessage, setErrorMessage] = React.useState('');
	const [success, setSuccess] = React.useState(false);
	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
	} = useForm();

	const createEclubUser = async () => {
		try {
			let res = await fetch(config.baseUrl + 'api/createEclubUser', {
				method: 'POST',
				body: JSON.stringify({
					email: watch('email'),
					fName: watch('firstName'),
					lName: watch('lastName'),
					company: watch('company'),
					comment: watch('comment'),
				}),
				headers: {
					'content-type': 'application/json;charset=utf-8',
				},
			});
			let resJson = await res.json();
			console.log(resJson);
			if (res.status === 200) {
				setSuccessMessage(
					'Thanks ' +
						watch('firstName') +
						', for sharing your information. Excited to learn more about how we can help you!'
				);
				setSuccess(true);

				// fNameSet('');
				// lNameSet('');
				// emailSet('');
			} else {
				setErrorMessage(resJson.error);
			}
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className='eclub-form-wrapper id="frame"'>
			<div className="eclub-form-success-message">{successMessage}</div>
			{!success ? (
				<form onSubmit={handleSubmit(createEclubUser)} className="eclub-form">
					<div className="eclub-form-intro">
						Interested in getting safetey and inventory peace of mind?
						<br />
						<br />
						Enter your info below for a free trial when we launch!
						<div className="eclub-form-error-message">{errorMessage}</div>
					</div>

					<label className="eclub-form-label-name">
						Name:
						<span className="required-field">*</span>
					</label>
					<input
						type="text"
						id="fname"
						placeholder="First Name"
						// value={fNameText}
						// onChange={(e) => fNameSet(e.target.value)}
						{...register('firstName', {
							required: 'First name is required',
							maxLength: {
								value: 30,
								message: 'First name must be less than 30 characters',
							},
						})}
						className="eclub-form-name-input-first"
					/>
					{errors?.firstName && (
						<p className="eclub-form-fname-error error">
							{errors.firstName?.message?.toString()}
						</p>
					)}
					<input
						type="text"
						id="lname"
						placeholder="Last Name"
						// value={lNameText}
						{...register('lastName', {
							required: 'Last name is required',
							maxLength: {
								value: 30,
								message: 'Last name must be less than 30 characters',
							},
						})}
						// onChange={(e) => lNameSet(e.target.value)}
						className="eclub-form-name-input-last"
					/>
					{errors?.lastName && (
						<p className="eclub-form-lname-error error">
							{errors.lastName?.message?.toString()}
						</p>
					)}
					<label className="eclub-form-label-email">
						Email: <span className="required-field">*</span>
					</label>
					<input
						type="email"
						placeholder="Email"
						id="email"
						className="eclub-form-email-input"
						// value={emailText}
						// onChange={(e) => emailSet(e.target.value)}
						{...register('email', {
							required: 'Email is required',
							pattern: {
								value:
									/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: 'Not a valid email',
							},
						})}
					/>
					{errors?.email && (
						<p className="eclub-form-email-error error">
							{errors.email?.message?.toString()}
						</p>
					)}
					<label className="eclub-form-label-company">
						Company: <span className="required-field">*</span>
					</label>
					<input
						type="text"
						placeholder="Company Name"
						id="company"
						// value={companyText}
						{...register('company', {
							required: 'Company name is required',
							maxLength: {
								value: 50,
								message: 'Company name must be less than 50 characters',
							},
						})}
						// onChange={(e) => companySet(e.target.value)}
						className="eclub-form-company-input"
					/>
					{errors?.company && (
						<p className="eclub-form-company-error error">
							{errors.company?.message?.toString()}
						</p>
					)}
					<label className="eclub-form-label-comment">
						Anything <br />
						Else:
					</label>
					<input
						type="text"
						placeholder="Questions? Problems that need a solution?"
						id="comment"
						// value={commentText}
						{...register('comment', { maxLength: 500 })}
						// onChange={(e) => commentSet(e.target.value)}
						className="eclub-form-comment-input"
					/>
					<input
						type="button"
						onClick={handleSubmit(createEclubUser)}
						value="Join Now"
						className="eclub-form-submit-button"
					/>
				</form>
			) : null}
		</div>
	);
}
