import React from 'react';
export default function Home() {
	const style = {
		pageTitle: {
			paddingTop: '30px',
			paddingBottom: '15px',
			fontSize: 'xx-large',
			fontWeight: 'bold',
			color: '#0f444c',
		},
		bodyArea: {
			display: 'flex',
			flexWrap: 'wrap' as const,
			flexDirection: 'row' as const,
			justifyContent: 'left' as const,
			paddingBottom: '20px',
		},
		sectionTitle: {
			fontSize: 'x-large',
			fontWeight: 'bold',
			textAlign: 'left' as const,
			paddingLeft: '10px',
		},
		bodyText: {
			textAlign: 'left' as const,
			paddingTop: '20px',
			paddingBottom: '20px',
			paddingLeft: '40px',
			paddingRight: '20px',
		},
		bodyImage: {
			height: '540px',
		},
	};
	return (
		<div className="App-content">
			<div className="pageTitle" style={style.pageTitle}>
				Get safety, peace of mind and manage key inventory!
			</div>
			<div className="bodyArea" style={style.bodyArea}>
				<div className="bodyText" style={style.bodyText}>
					<div className="sectionTitle" style={style.sectionTitle}>
						Key features
					</div>
					<p className="body-section" style={style.bodyText}>
						<li>
							Access and manage Material Data Safety Sheets for your worksite
						</li>
						<br />
						<li>Mobile app for iOS and Android </li>
						<br />
						<li>Offline access for wherever you need it</li>
						<br />
						<li>Keep track of inventory in easy to use admin portal</li>
					</p>
					<div className="sectionTitle" style={style.sectionTitle}>
						How it works
					</div>
					<p className="body-section" style={style.bodyText}>
						1. Scan barcodes of items that need MSDS sheets!
						<br />
						<br />
						2. Store MSDS Sheets for offline use (always ready even when the
						internet isn't)
						<br />
						<br />
						3. Manage key inventory so you operations are never blocked!
					</p>
					App launching soon!
				</div>
				<img src={'../images/pb_scan_phone.png'} style={style.bodyImage} />
			</div>
		</div>
	);
}
